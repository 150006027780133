<template>
  <div>
    <div hidden>
      <button class="clear-values-btn" @click="clearValues()"></button>
    </div>
    <!-- Desktop View -->
    <div class="table-responsive card desktop-logic" style="padding: 0 1rem">
      <div
        @click="
          () => {
            show = !show;
          }
        "
        style="
          font-size: 1.5rem;
          padding: 0.7rem;
          font-weight: 600;
          display: flex;
          align-items: center;
        "
      >
        {{ ea ? "Customer Action Plans" : "My Action Plans" }}
        <svg
          v-show="!show"
          style="height: 1.5rem; margin-left: auto"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          id="action-plan-show"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
          />
        </svg>
        <svg
          v-show="show"
          style="height: 1.5rem; margin-left: auto"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          id="action-plan-hide"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
          />
        </svg>
      </div>
      <div class="fit-content-l840" v-show="show">
        <div
          style="
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 0 1rem 0.3rem 1rem;
          "
        >
          <div style="display: flex; flex-direction: row; flex-wrap: wrap">
            <label style="font-size: medium; margin-right: 1rem"
              >Items Per Page:</label
            >
            <div style="display: flex; flex-direction: column">
              <div style="display: flex; flex-direction: row">
                <input
                  class="page-amount-input"
                  :value="currentPageLength"
                  @change="changePageLength($event.target.value)"
                />
                <svg
                  v-show="!showPageAmountDrpDwn"
                  @click="showPageAmountDrpDwn = true"
                  class="tgl-page-dropdown"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                  />
                </svg>
                <svg
                  v-show="showPageAmountDrpDwn"
                  @click="showPageAmountDrpDwn = false"
                  class="tgl-page-dropdown"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                  />
                </svg>
              </div>
              <div class="page-num-options" v-show="showPageAmountDrpDwn">
                <div
                  v-for="index in Math.ceil(getAuditAmount / pageIncrements)"
                  :key="index"
                  @click="
                    () => {
                      changePageLength(index * pageIncrements);
                      showPageAmountDrpDwn = false;
                    }
                  "
                >
                  {{ index * pageIncrements }}
                </div>
              </div>
            </div>

            <div style="margin-left: 2rem; margin-right: 1.5rem; display: flex">
              <label style="font-size: medium; margin-right: 1rem"
                >From Date:</label
              >
              <input
                style="margin-right: 1.4rem"
                class="date-range-input"
                :max="
                  searchToDate != null && searchToDate != ''
                    ? formatDateInputString(new Date(searchToDate))
                    : null
                "
                type="date"
                @input="searchFromDate = $event.target.value"
              />
              <label style="font-size: medium; margin-right: 1rem"
                >To Date:</label
              >
              <input
                style="margin-right: 1.4rem"
                class="date-range-input"
                :min="
                  searchFromDate != null && searchFromDate != ''
                    ? formatDateInputString(new Date(searchFromDate))
                    : null
                "
                @input="searchToDate = $event.target.value"
                type="date"
              />
              <label style="font-size: medium; margin-right: 1rem"
                >Search By:</label
              >
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <div class="search-type-dis">{{ searchType }}</div>
                  <svg
                    v-show="!showSearchTypeDrpDwn"
                    @click="showSearchTypeDrpDwn = true"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                    />
                  </svg>
                  <svg
                    v-show="showSearchTypeDrpDwn"
                    @click="showSearchTypeDrpDwn = false"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                    />
                  </svg>
                </div>
                <div class="search-type-options" v-show="showSearchTypeDrpDwn">
                  <div
                    @click="
                      () => {
                        searchType = 'Created On';
                        showSearchTypeDrpDwn = false;
                      }
                    "
                  >
                    Created On
                  </div>
                  <div
                    @click="
                      () => {
                        searchType = 'Accepted On';
                        showSearchTypeDrpDwn = false;
                      }
                    "
                  >
                    Accepted On
                  </div>
                </div>
              </div>
            </div>
            <button
              style="margin: 0; background-color: #ededed; font-size: small"
              @click="openSetAuditDesc()"
              class="btn"
              v-if="ea"
            >
              Produce Signable Action Plan
            </button>
          </div>
        </div>
        <table id="datatable-basic" class="table table-flush">
          <thead class="thead-light">
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Version
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Subject
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Created On
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Accepted On
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Description
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Contract
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getNextAudits" :key="index">
              <td
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
              >
                {{ item.version }}
              </td>
              <td
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
              >
                <div :title="item.reason" style="cursor: pointer">
                  {{
                    item.reason.length > 25
                      ? item.reason.substring(0, 25) + "..."
                      : item.reason
                  }}
                </div>
              </td>
              <td
                style="padding: 0.75rem 1.5rem; color: limegreen"
                class="text-sm font-weight-normal"
                v-if="item.created != null"
              >
                {{ formatDate(new Date(item.created)) }}
              </td>
              <td
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
                v-if="item.created == null"
              >
                N/A
              </td>
              <td
                style="padding: 0.75rem 1.5rem; color: limegreen"
                class="text-sm font-weight-normal"
                v-if="item.accepted != null"
              >
                {{ formatDate(new Date(item.accepted)) }}
              </td>
              <td
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
                v-if="item.accepted == null"
              >
                N/A
              </td>
              <td
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
              >
                <!-- <div
                  class="hover-tooltip"
                  :id="'description-tip-' + index"
                  @mouseout="hideToolTip(index, 'description')"
                  hidden
                >
                  <div></div>
                  <div>{{ item.description }}</div>
                </div>
                <span @mouseover="showToolTip(index, 'description')">
                  {{
                    item.description.length > 20
                      ? item.description.substring(0, 20) + "..."
                      : item.description
                  }}
                </span> -->
                <div
                  v-if="
                    item.contentType != 'text/rtf' &&
                    item.contentType != 'text/markdown'
                  "
                  :title="item.description"
                  style="cursor: pointer"
                >
                  {{
                    item.description.length > 20
                      ? item.description.substring(0, 20) + "..."
                      : item.description
                  }}
                </div>
                <!-- <div v-html="item.description" :title="item.description" v-if="item.contentType == 'text/rtf'">
                </div> -->
                <div
                  :id="item.version + '-tooltip'"
                  class="desc-tooltip card"
                  v-html="item.description"
                  v-if="
                    item.contentType == 'text/rtf' ||
                    item.contentType == 'text/markdown'
                  "
                  hidden
                ></div>
                <div
                  style="cursor: pointer"
                  @mouseover="showTooltip(item.version + '-tooltip', $event)"
                  v-if="
                    item.contentType == 'text/rtf' ||
                    item.contentType == 'text/markdown'
                  "
                >
                  <svg
                    v-if="item.accepted == null && ea"
                    @click="openEditDescription(item)"
                    class="edit-desc-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                    />
                  </svg>
                  Preview
                </div>
              </td>
              <!-- <td style="padding: 0.75rem 1.5rem;" class="text-sm font-weight-normal">{{item.description}}</td> -->
              <td
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
                v-if="item.statusCode != 'FCC_ActionPlanAudit_ETL'"
              >
                <router-link
                  class="contract-link"
                  :to="getPreviewContractHref + item.version"
                  v-if="item.accepted == null && ea"
                  >Preview Contract</router-link
                ><br v-if="item.accepted == null && ea" />
                <router-link
                  class="contract-link"
                  :to="getSignContractHref + item.version"
                  v-if="item.accepted == null && ea"
                  >Sign Electronically</router-link
                ><br v-if="item.accepted == null && ea" />
                <!-- <a class="view-contract" style="text-decoration: underline;" v-if="item.accepted == null" :href="getSignContractHref + item.version">
                          Sign Electronically
                      </a><br/> -->
                <!-- <router-link class="contract-link" :to="getassessmentHref" v-if="item.accepted == null">Test Survey</router-link><br/> -->
                <a
                  class="view-contract"
                  style="text-decoration: underline"
                  v-if="item.accepted == null && ea"
                  @click="downloadContract(item.version)"
                >
                  Download PDF to Sign </a
                ><br v-if="item.accepted == null && ea" />
                <a
                  class="view-contract"
                  style="text-decoration: underline"
                  v-if="item.accepted == null && ea"
                  @click="uploadContract(item.version)"
                >
                  Upload Signed Copy </a
                ><br v-if="item.accepted == null && ea" />
                <div
                  v-show="showUploadError && uploadErrorVersion == item.version"
                  class="text-danger"
                >
                  File has to be a PDF.
                </div>
                <a
                  class="view-contract"
                  style="text-decoration: underline"
                  v-if="item.accepted != null"
                  @click="openContract(item.version)"
                >
                  View Signed PDF
                </a>
                <div
                  v-if="item.accepted == null && !ea"
                  @click="openContract(item.version)"
                >
                  Not available
                </div>
              </td>
              <td
                style="padding: 0.75rem 1.5rem"
                class="text-sm font-weight-normal"
                v-if="item.statusCode == 'FCC_ActionPlanAudit_ETL'"
              >
                <span style="color: grey">Refer to Cares</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="page-btn-container">
          <div
            v-if="currentPageNum > 1"
            class="page-btn back-page"
            @click="
              changePageNumOptions(currentPageNum, currentPageNum - 1),
                changePage(currentPageNum - 1)
            "
          >
            <b>&#60;</b>
          </div>
          <div v-if="currentPageNum == 1" class="page-btn back-page unactive">
            <b>&#60;</b>
          </div>
          <div
            v-for="num in currentPageNumOptions.length > getMaxPages
              ? getMaxPages
              : currentPageNumOptions"
            :key="num"
            :class="
              num == currentPageNum
                ? 'selected-page-btn selected-page-btn' +
                  uniqueId +
                  ' page-btn' +
                  uniqueId
                : 'page-btn' + uniqueId
            "
            class="page-btn"
            @click="changePage(num)"
          >
            {{ num }}
          </div>
          <div
            v-if="currentPageNumOptions.length < getMaxPages"
            class="page-btn select-pg-num"
            @click="togglePageSelect()"
            :id="'page-cont' + uniqueId"
          >
            <b>...</b>
          </div>
          <div
            v-if="currentPageNum < getMaxPages"
            class="page-btn forw-page"
            @click="
              () => {
                changePageNumOptions(currentPageNum, currentPageNum + 1),
                  changePage(currentPageNum + 1);
              }
            "
          >
            <b style="display: flex; align-items: center">&#62;</b>
          </div>
          <div
            v-if="currentPageNum == getMaxPages"
            class="page-btn forw-page unactive"
          >
            <b>&#62;</b>
          </div>
          <div
            class="page-selector"
            :class="
              getMaxPages <= 7
                ? 'page-selctor-1'
                : getMaxPages > 7 && getMaxPages <= 14
                ? 'page-selctor-2'
                : getMaxPages > 14
                ? 'page-selctor-3'
                : 'page-selctor-1'
            "
            :id="'page-select' + uniqueId"
            hidden
          >
            <div
              v-for="index in getMaxPages"
              :key="index"
              @click="pageNumSelected(index)"
            >
              {{ index }}
            </div>
          </div>
        </div>
        <div id="act-view" hidden><ContractView /></div>
        <div
          id="edit-audit-desc"
          style="overflow-y: auto; max-height: 60vh"
          hidden
        >
          <div>Formal Review:</div>
          <div id="edit-audit-desc-editor"></div>
          <!-- <textarea class="audit-desc-ta" v-model="newAuditDesc" @input="isAuditSentence(newAuditDesc)"/><br/> -->
          <span class="text-danger" v-show="!auditDescIsSentence"
            >Please enter a sentence.</span
          ><br />
          <button
            class="btn"
            @click="
              () => {
                editDesc();
              }
            "
          >
            Confirm and Create
          </button>
          <!-- <button class="btn" v-show="newAuditDesc != null && newAuditDesc != '' && auditDescIsSentence && newAuditSubj != null" @click="()=>{createAuditPoint(); toggleModal(); clearAuditSubjCheckboxes(null); newAuditDesc =null;}">Confirm and Create</button>
          <button class="btn" v-show="newAuditDesc == null || newAuditDesc == '' || !auditDescIsSentence || newAuditSubj == null" disabled>Confirm and Create</button> -->
        </div>
      </div>
    </div>

    <!-- Mobile View -->
    <div class="card open-action-cont open-actions-card mobile-logic">
      <!-- Table Header -->
      <div
        @click="
          () => {
            show = !show;
          }
        "
        style="
          font-size: 1.5rem;
          padding: 0.7rem;
          font-weight: 600;
          display: flex;
          align-items: center;
        "
      >
        {{ ea ? "Customer Action Plans" : "My Action Plans" }}
        <svg
          v-show="!show"
          style="
            min-width: 24px;
            flex-basis: 24px;
            height: 1.5rem;
            margin-left: auto;
          "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"
          />
        </svg>
        <svg
          v-show="show"
          style="
            min-width: 24px;
            flex-basis: 24px;
            height: 1.5rem;
            margin-left: auto;
          "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z"
          />
        </svg>
      </div>

      <div v-show="show">
        <!-- Search Filters -->
        <div
          style="
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 0 0.7rem 0.3rem 0.7rem;
          "
        >
          <div style="margin-right: 1.5rem; display: block">
            <button
              style="
                margin: 0;
                margin-bottom: 1rem;
                background-color: #ededed;
                font-size: small;
              "
              @click="openSetAuditDesc()"
              class="btn"
              v-if="ea"
            >
              Produce Signable Action Plan
            </button>

            <div style="display: flex; flex-direction: row; flex-wrap: wrap">
              <label style="font-size: medium; margin-right: 1rem; width: 8rem"
                >Items Per Page:</label
              >
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <input
                    class="page-amount-input"
                    :value="currentPageLength"
                    @change="changePageLength($event.target.value)"
                  />
                  <svg
                    v-show="!showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = true"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                    />
                  </svg>
                  <svg
                    v-show="showPageAmountDrpDwn"
                    @click="showPageAmountDrpDwn = false"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                    />
                  </svg>
                </div>
                <div class="page-num-options" v-show="showPageAmountDrpDwn">
                  <div
                    v-for="index in Math.ceil(getAuditAmount / pageIncrements)"
                    :key="index"
                    @click="
                      () => {
                        changePageLength(index * pageIncrements);
                        showPageAmountDrpDwn = false;
                      }
                    "
                  >
                    {{ index * pageIncrements }}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <label style="font-size: medium; margin-right: 1rem; width: 8rem"
                >From Date:</label
              >
              <input
                type="date"
                style="margin-right: 1.4rem"
                class="date-range-input"
                :value="
                  searchFromDate != null && searchFromDate != ''
                    ? formatDateInputString(new Date(searchFromDate))
                    : null
                "
                :max="searchToDate"
                @change="searchFromDate = $event.target.value"
              />
            </div>

            <div>
              <label style="font-size: medium; margin-right: 1rem; width: 8rem"
                >To Date:</label
              >
              <input
                type="date"
                style="margin-right: 1.4rem"
                class="date-range-input"
                :value="
                  searchToDate != null && searchToDate != ''
                    ? formatDateInputString(new Date(searchToDate))
                    : null
                "
                :min="searchFromDate"
                @change="searchToDate = $event.target.value"
              />
            </div>

            <div style="display: flex; flex-direction: row; flex-wrap: wrap">
              <label style="font-size: medium; margin-right: 1rem; width: 8rem"
                >Search By:</label
              >
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                  <div class="search-type-dis">{{ searchType }}</div>
                  <svg
                    v-show="!showSearchTypeDrpDwn"
                    @click="showSearchTypeDrpDwn = true"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"
                    />
                  </svg>
                  <svg
                    v-show="showSearchTypeDrpDwn"
                    @click="showSearchTypeDrpDwn = false"
                    class="tgl-page-dropdown"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"
                    />
                  </svg>
                </div>
                <div class="search-type-options" v-show="showSearchTypeDrpDwn">
                  <div
                    @click="
                      () => {
                        searchType = 'Created On';
                        showSearchTypeDrpDwn = false;
                      }
                    "
                  >
                    Created On
                  </div>
                  <div
                    @click="
                      () => {
                        searchType = 'Accepted On';
                        showSearchTypeDrpDwn = false;
                      }
                    "
                  >
                    Accepted On
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Audit Item -->
        <div
          class="card h-100 open-action"
          v-for="(item, index) in getNextAudits"
          :key="index"
        >
          <div class="p-3 card-header" :style="'padding-bottom: 0;'">
            <div style="display: flex; flex-direction: row">
              <div style="overflow: hidden; display: block; width: 100%">
                <h6 class="subject" style="width: 100%">
                  {{ item.reason }} ({{ item.version }})
                </h6>
                <h6 style="width: 100%; color: #67748e">
                  {{
                    item.created != null
                      ? formatDate(new Date(item.created))
                      : "N/A"
                  }}
                </h6>
              </div>

              <div style="width: 24px; height: 100%">
                <svg
                  @click="onAuditSelect(item)"
                  style="width: 100%"
                  v-show="!extendedItems.includes(item)"
                  class="extend-action"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                  />
                </svg>
                <svg
                  @click="onAuditDeselect(item)"
                  style="width: 100%"
                  v-show="extendedItems.includes(item)"
                  class="extend-action"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <!-- Audit Item Content (drops down) -->
          <div
            class="card-body"
            v-show="extendedItems.includes(item)"
            style="padding: 0 1rem 1rem 1rem"
          >
            <ul class="list-group">
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Created On:</strong> &nbsp;
                <div :style="item.created != null ? 'color: limegreen' : ''">
                  {{
                    item.created != null
                      ? formatDate(new Date(item.created))
                      : ""
                  }}
                </div>
              </li>
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Accepted On:</strong> &nbsp;
                <div :style="item.accepted != null ? 'color: limegreen' : ''">
                  {{
                    item.accepted != null
                      ? formatDate(new Date(item.accepted))
                      : "N/A"
                  }}
                </div>
              </li>
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Description:</strong> &nbsp;
                <svg
                  v-if="item.accepted == null && ea"
                  @click="openEditDescription(item)"
                  class="edit-desc-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                  />
                </svg>
                <div>
                  <div
                    v-if="
                      item.contentType != 'text/rtf' &&
                      item.contentType != 'text/markdown'
                    "
                    :title="item.description"
                    style="
                      cursor: pointer;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ item.description }}
                  </div>
                  <div
                    :id="item.version + '-tooltip-mobile'"
                    class="desc-tooltip card"
                    v-html="item.description"
                    v-if="
                      item.contentType == 'text/rtf' ||
                      item.contentType == 'text/markdown'
                    "
                    hidden
                  ></div>
                  <div
                    style="cursor: pointer"
                    @mouseover="
                      showTooltip(item.version + '-tooltip-mobile', $event)
                    "
                    v-if="
                      item.contentType == 'text/rtf' ||
                      item.contentType == 'text/markdown'
                    "
                  >
                    Preview
                  </div>
                </div>
              </li>
              <li
                class="pt-0 text-sm border-0 list-group-item ps-0"
                v-if="item.statusCode == 'FCC_ActionPlanAudit_ETL'"
              >
                <span style="color: grey">Refer to Cares</span>
              </li>
              <li
                v-if="
                  item.accepted == null &&
                  ea &&
                  item.statusCode != 'FCC_ActionPlanAudit_ETL'
                "
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <router-link
                  class="contract-link"
                  :to="getPreviewContractHref + item.version"
                  >Preview Contract
                </router-link>
              </li>

              <li
                v-if="
                  item.accepted == null &&
                  ea &&
                  item.statusCode != 'FCC_ActionPlanAudit_ETL'
                "
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <router-link
                  class="contract-link"
                  :to="getSignContractHref + item.version"
                  >Sign Electronically
                </router-link>
              </li>

              <li
                v-if="
                  item.accepted == null &&
                  ea &&
                  item.statusCode != 'FCC_ActionPlanAudit_ETL'
                "
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <a
                  class="view-contract"
                  style="text-decoration: underline"
                  @click="downloadContract(item.version)"
                >
                  Download PDF to Sign
                </a>
              </li>

              <li
                v-if="
                  item.accepted == null &&
                  ea &&
                  item.statusCode != 'FCC_ActionPlanAudit_ETL'
                "
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <a
                  class="view-contract"
                  style="text-decoration: underline"
                  @click="uploadContract(item.version)"
                >
                  Upload Signed Copy
                </a>
              </li>
              <li
                v-show="showUploadError && uploadErrorVersion == item.version"
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <span class="text-danger">File has to be a PDF.</span>
              </li>
              <li class="pt-0 text-sm border-0 list-group-item ps-0">
                <input
                  type="file"
                  :id="'contractUploader-' + item.version"
                  name="filename"
                  @input="contractUploaded(item.version)"
                  hidden
                />
              </li>

              <li
                v-if="
                  item.accepted != null &&
                  item.statusCode != 'FCC_ActionPlanAudit_ETL'
                "
                class="pt-0 text-sm border-0 list-group-item ps-0"
              >
                <a
                  class="view-contract"
                  style="text-decoration: underline"
                  @click="openContract(item.version)"
                >
                  View Signed PDF
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-btn-container">
          <div
            v-if="currentPageNum > 1"
            class="page-btn back-page"
            @click="
              changePageNumOptions(currentPageNum, currentPageNum - 1),
                changePage(currentPageNum - 1)
            "
          >
            <b>&#60;</b>
          </div>
          <div v-if="currentPageNum == 1" class="page-btn back-page unactive">
            <b>&#60;</b>
          </div>
          <div
            v-for="num in currentPageNumOptions.length > getMaxPages
              ? getMaxPages
              : currentPageNumOptions"
            :key="num"
            :class="
              num == currentPageNum
                ? 'selected-page-btn selected-page-btn' +
                  uniqueId +
                  ' page-btn' +
                  uniqueId
                : 'page-btn' + uniqueId
            "
            class="page-btn"
            @click="changePage(num)"
          >
            {{ num }}
          </div>
          <div
            v-if="currentPageNumOptions.length < getMaxPages"
            class="page-btn select-pg-num"
            @click="togglePageSelect()"
            :id="'page-cont' + uniqueId"
          >
            <b>...</b>
          </div>
          <div
            v-if="currentPageNum < getMaxPages"
            class="page-btn forw-page"
            @click="
              () => {
                changePageNumOptions(currentPageNum, currentPageNum + 1),
                  changePage(currentPageNum + 1);
              }
            "
          >
            <b style="display: flex; align-items: center">&#62;</b>
          </div>
          <div
            v-if="currentPageNum == getMaxPages"
            class="page-btn forw-page unactive"
          >
            <b>&#62;</b>
          </div>
          <div
            class="page-selector"
            :class="
              getMaxPages <= 7
                ? 'page-selctor-1'
                : getMaxPages > 7 && getMaxPages <= 14
                ? 'page-selctor-2'
                : getMaxPages > 14
                ? 'page-selctor-3'
                : 'page-selctor-1'
            "
            :id="'page-select' + uniqueId"
            hidden
          >
            <div
              v-for="index in getMaxPages"
              :key="index"
              @click="pageNumSelected(index)"
            >
              {{ index }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Quill from "quill";
import { useCommonStore } from "@/store/commonStore";
import { useAdvisorDashboardStore } from "@/store/advisorDashboard";
import { mapState, mapActions } from "pinia";
import ContractView from "./ContractView.vue";
//import mammoth from 'mammoth';
//import rtfToHTML from "@iarna/rtf-to-html";
import { RTFJS } from "rtf.js";
//import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import Turndown from "turndown";
import { contractFilter } from '@/composables/contractFilter';

var turndownService = new Turndown();
let editedAuditDesc = null;
let editedDescText = null;
let quill = null;
export default {
  name: "audit-table",
  components: {
    ContractView,
    // RichTextRenderer
  },
  data() {
    return {
      extendedItems: [],
      currentPageNum: 1,
      currentPageLength: this.pageLength,
      showPageAmountDrpDwn: false,
      show: false,
      currentPageNumOptions: [1, 2, 3, 4],
      searchFromDate: null,
      searchToDate: null,
      searchType: "Created On",
      showSearchTypeDrpDwn: false,
      auditDescIsSentence: true,
      auditDescToEdit: null,
      editingAuditVersion: null,
      showUploadError: false,
      uploadErrorVersion: null,
    };
  },
  props: {
    ea: {
      type: Boolean,
      required: true,
    },
    pageLength: {
      type: Number,
      default: 5,
    },
    pageIncrements: {
      type: Number,
      default: 5,
    },
    uniqueId: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState(useAdvisorDashboardStore, [
      "getContractView",
      "getAuditDescView",
      "getEditAuditDescView",
      "getCompleterSummaryData",
    ]),
    ...mapState(useCommonStore, [
      "getCustomerId",
      "getCurrentContract",
      "getAuditPoints",
      "getCustomer",
      "getCurrentAuditPointActions",
      "getCurrentAuditToSign",
      "getPRaPOrder",
      "getUpcomingMeetings"
    ]),

    customerProgram() {
      const { customerProgram } = contractFilter();
      return customerProgram?.value || null; // Return the value or null if undefined
    },


    currentPrap() {
      return this.getPRaPOrder?.documents?.slice(-1)[0] || null;
    },

    customerNino() {
      const currentPRaP = this.currentPrap;
      return currentPRaP ? currentPRaP.extraInfoLine.ninoReferenceNo : "N/A";
    },

    latestExitPlanSummaryData() {
      // Filter for audit points with the reason "Exit Plan"
      const exitPlanPoints = this.getAuditPoints.filter(
        (point) => point.reason === "Exit Plan"
      );

      if (exitPlanPoints.length > 0) {
        // If there are any "Exit Plan" points, return the completerSummaryData from the first one (latest)
        return exitPlanPoints[0].completerSummaryData;
      } else {
        // If no "Exit Plan" points are found, return null or a default value
        return null;
      }
    },

    getMaxPages() {
      return Math.ceil(this.getFilteredAudits.length / this.currentPageLength);
    },
    getFilteredAudits() {
      var audits = this.getAuditPoints;
      if (this.searchFromDate != null && this.searchFromDate != "") {
        switch (this.searchType) {
          case "Created On":
            audits = this.getAuditPoints.filter(
              (a) =>
                new Date(a.created).setHours(0, 0, 0, 0) >=
                new Date(this.searchFromDate).setHours(0, 0, 0, 0)
            );
            break;
          case "Accepted On":
            audits = this.getAuditPoints.filter(
              (a) =>
                new Date(a.accepted).setHours(0, 0, 0, 0) >=
                new Date(this.searchFromDate).setHours(0, 0, 0, 0)
            );
            break;
        }
      }
      if (this.searchToDate != null && this.searchToDate != "") {
        switch (this.searchType) {
          case "Created On":
            audits = audits.filter(
              (a) =>
                new Date(a.created).setHours(0, 0, 0, 0) <=
                new Date(this.searchToDate).setHours(0, 0, 0, 0)
            );
            break;
          case "Accepted On":
            audits = audits.filter(
              (a) =>
                new Date(a.accepted).setHours(0, 0, 0, 0) <=
                new Date(this.searchToDate).setHours(0, 0, 0, 0)
            );
            break;
        }
      }
      return audits;
    },
    getNextAudits() {
      if (this.getFilteredAudits.length > 0) {
        return this.getFilteredAudits.slice(
          (this.currentPageNum - 1) * this.currentPageLength,
          (this.currentPageNum - 1) * this.currentPageLength +
            this.currentPageLength
        );
      }
      return this.getFilteredAudits;
    },
    getAuditAmount() {
      return this.getFilteredAudits.length;
    },
    getSignContractHref() {
      return `/signContract/${this.getCustomerId}/${
        this.ea ? "ea" : "customer"
      }/`;
    },
    getPreviewContractHref() {
      return `/PreviewContract/${this.getCustomerId}/`;
    },
    getassessmentHref() {
      return `/ThemedAssessment/${this.getCustomerId}/test/current`;
    },
  },
  watch: {
    auditDescToEdit() {
      // var parent = document.getElementById("edit-audit-desc");
      // parent.getElementsByClassName("ql-editor")[0].innerHTML = this.auditDescToEdit;
      if (quill != null) {
        quill.clipboard.dangerouslyPasteHTML(this.auditDescToEdit);
      }
    },
  },
  methods: {
    ...mapActions(useCommonStore, [
      "GetContract",
      "GetAuditPoints",
      "GetCustomer",
      "GetAuditActions",
      "UploadContract",
      "GetAuditByVersion",
      "UpdateAuditDescription",
    ]),
    ...mapActions(useAdvisorDashboardStore, [
      "toggleModal",
      "setModalContent",
      "setModalTitle",
      "setContractView",
      "generateContract",
      "setAuditDescView",
      "setEditAuditDescView",
      
    ]),
    onAuditSelect(audit) {
      this.extendedItems.push(audit);
    },
    onAuditDeselect(audit) {
      this.extendedItems = this.extendedItems.filter((a) => a != audit);
    },
    showTooltip(id, event) {
      document.getElementById(id).hidden = false;
      event.target.addEventListener("mouseout", ($event) => {
        setTimeout(() => {
          this.hideTooltip($event);
        }, 1000);
      });
    },
    hideTooltip(event) {
      var div = event.target;
      while (div.nodeName != "DIV") {
        div = div.parentNode;
      }
      div.previousSibling.hidden = true;
    },
    openEditDescription(audit) {
      this.auditDescToEdit = audit.description;
      this.editingAuditVersion = audit.version;
      if (this.getEditAuditDescView == null) {
        this.setEditAuditDescView(document.getElementById("edit-audit-desc"));
      }
      this.setModalContent(this.getEditAuditDescView);
      this.setModalTitle("Edit Action Plan Notes");
      this.toggleModal();
    },
    editDesc() {
      if (editedDescText.length < 20) {
        this.auditDescIsSentence = false;
        return;
      }
      this.toggleModal();
      this.UpdateAuditDescription(
        this.editingAuditVersion,
        editedAuditDesc,
        true
      );
      editedAuditDesc = null;
      editedDescText = null;
      this.auditDescIsSentence = true;
      this.auditDescToEdit = null;
      this.editingAuditVersion = null;
      var parent = document.getElementById("edit-audit-desc");
      parent.getElementsByClassName("ql-editor")[0].innerHTML = "";
    },
    async ConvertFromRtfToHtml(rtf, contId) {
      var container = document.getElementById(contId);
      if (container) {
        if (container.classList.contains("rendered-desc")) {
          var childrenAmount = container.children.length;
          var count = 0;
          while (count < childrenAmount) {
            container.removeChild(container.firstChild);
            count++;
          }
          container.classList.remove("rendered-desc");
        }
        const stringToArrayBuffer = (string) => {
          const buffer = new ArrayBuffer(string.length);
          const bufferView = new Uint8Array(buffer);
          for (let i = 0; i < string.length; i++) {
            bufferView[i] = string.charCodeAt(i);
          }
          return buffer;
        };
        const doc = new RTFJS.Document(stringToArrayBuffer(rtf));
        const htmlElements = await doc.render();
        htmlElements.map((elem) => container.appendChild(elem));
        container.classList.add("rendered-desc");
      }
    },
    formatDate(date) {
      return [
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1,
        date.getFullYear(),
      ].join("/");
    },
    formatDateInputString(date) {
      return [
        date.getFullYear(),
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1,
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
      ].join("-");
    },
    openSetAuditDesc() {
      if (this.getAuditDescView == null) {
        this.setAuditDescView(document.getElementById("create-audit-desc"));
      }
      this.setModalContent(this.getAuditDescView);
      this.setModalTitle("");
      this.toggleModal();
    },
    uploadContract(version) {
      document.getElementById("contractUploader-" + version).click();
    },
    async contractUploaded(version) {
      if (
        document.getElementById("contractUploader-" + version).files[0].type !=
        "application/pdf"
      ) {
        this.showUploadError = true;
        this.uploadErrorVersion = version;
      } else {
        this.showUploadError = false;
        this.uploadErrorVersion = null;
        var contract = await this.GetByteArrayFromBlob(
          document.getElementById("contractUploader-" + version).files[0]
        );
        this.UploadContract(version, contract);
      }
    },
    async GetByteArrayFromBlob(blob) {
      var bytes = await new Response(blob).arrayBuffer();
      return bytes;
    },

    async downloadContract(version) {
      await Promise.all([
        this.GetAuditActions(version),
        this.GetCustomer(),
        this.GetAuditByVersion(version),
      ]);
      
      const nino = this.customerProgram === 'FSS' ? 'Participant NI Number: ' + this.customerNino : null;

      var contract = await this.generateContract(
        null,
        null,
        this.getCurrentAuditPointActions,
        this.getCustomer,
        this.getCurrentAuditToSign,
        null,
        this.latestExitPlanSummaryData,
        nino,
        this.getUpcomingMeetings
      );
      contract.download("ActionPlanContract " + this.formatDate(new Date()));
    },

    openContract(version) {
      this.GetContract(version);
    },
    showToolTip(actionId, type) {
      var tooltip;
      if (type == "description") {
        tooltip = document.getElementById("description-tip-" + actionId);
      }
      if (tooltip != undefined) {
        tooltip.hidden = false;
      }
    },
    hideToolTip(actionId, type) {
      var tooltip;
      if (type == "description") {
        tooltip = document.getElementById("description-tip-" + actionId);
      }
      if (tooltip != undefined) {
        tooltip.hidden = true;
      }
    },
    displayContract(customerId, auditId) {
      if (this.getContractView == null) {
        this.setContractView(document.getElementById("act-view"));
      }
      this.setModalContent(this.getContractView);
      this.setModalTitle("View Contract");
      this.toggleModal();
      this.GetContract(customerId, auditId);
    },
    changePageNumOptions(oldPageNum, newPageNum) {
      if (oldPageNum < newPageNum) {
        if (
          this.currentPageNumOptions[this.currentPageNumOptions.length - 1] <
          newPageNum
        ) {
          for (var l = 0; l < this.currentPageNumOptions.length; l++) {
            this.currentPageNumOptions[l] = this.currentPageNumOptions[l] + 1;
          }
        }
      } else {
        if (this.currentPageNumOptions[0] > newPageNum) {
          for (var j = 0; j < this.currentPageNumOptions.length; j++) {
            this.currentPageNumOptions[j] = this.currentPageNumOptions[j] - 1;
          }
        }
      }
    },
    togglePageSelect() {
      var pageSelect = document.getElementById("page-select" + this.uniqueId);
      if (pageSelect.hidden) {
        pageSelect.hidden = false;
        document
          .getElementById("page-cont" + this.uniqueId)
          .classList.add("page-cont-active");
      } else {
        pageSelect.hidden = true;
        document
          .getElementById("page-cont" + this.uniqueId)
          .classList.remove("page-cont-active");
      }
    },
    changePage(newPageNum) {
      this.currentPageNum = parseInt(newPageNum);
      if (
        document.getElementsByClassName("selected-page-btn" + this.uniqueId)
          .length > 0
      ) {
        document
          .getElementsByClassName("selected-page-btn" + this.uniqueId)[0]
          .classList.remove("selected-page-btn");
        document
          .getElementsByClassName("selected-page-btn" + this.uniqueId)[0]
          .classList.remove("selected-page-btn" + this.uniqueId);
      }
    },
    pageNumSelected(index) {
      this.changePage(index);
      var check = this.getMaxPages - index;
      switch (check) {
        case 2:
          this.currentPageNumOptions = [index - 1, index, index + 1, index + 2];
          break;
        case 1:
          this.currentPageNumOptions = [index - 2, index - 1, index, index + 1];
          break;
        case 0:
          this.currentPageNumOptions = [index - 3, index - 2, index - 1, index];
          break;
        default:
          this.currentPageNumOptions = [index, index + 1, index + 2, index + 3];
          break;
      }
      this.togglePageSelect();
    },
    changePageLength(length) {
      length = parseInt(length);
      if (this.getMaxPages > this.getAuditPoints.length / length) {
        this.changePage(Math.ceil(this.getAuditPoints.length / length));
      }
      this.currentPageLength = length;
    },
    clearValues() {
      var parent = document.getElementById("edit-audit-desc");
      parent.getElementsByClassName("ql-editor")[0].innerHTML = "";
      editedAuditDesc = null;
      editedDescText = null;
      this.auditDescIsSentence = true;
      this.auditDescToEdit = null;
      this.editingAuditVersion = null;
    },
  },
  mounted() {
    if (document.getElementById("edit-audit-desc-editor")) {
      quill = new Quill("#edit-audit-desc-editor", {
        theme: "snow", // Specify theme in configuration
      });
      quill.on("text-change", function () {
        editedDescText = quill.getText();
        console.debug(quill.root.innerHTML);
        editedAuditDesc = turndownService.turndown(quill.root.innerHTML);
        console.debug(editedAuditDesc);
      });
    }
    // document.getElementById("modal-close").addEventListener("click", () => {
    //   var parent = document.getElementById("edit-audit-desc");
    //   parent.getElementsByClassName("ql-editor")[0].innerHTML = "";
    //   editedAuditDesc = null;
    //   editedDescText = null;
    //   (this.auditDescIsSentence = true),
    //     (this.auditDescToEdit = null),
    //     (this.editingAuditVersion = null);
    // });
  },
};

//<svg @click="openEditDescription(item)" class="edit-desc-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
</script>
<style>
.contract-link {
  text-decoration: underline;
  letter-spacing: -0.025rem;
  color: #344767;
}

.contract-link:hover {
  text-decoration: underline;
}

.hover-tooltip {
  z-index: 2;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.hover-tooltip div:last-child {
  padding: 0.5rem;
  border: 1px solid #5b5b5b;
  color: #5b5b5b;
  background-color: rgb(245, 245, 245);
  font-size: smaller;
  max-width: 25rem;
  white-space: pre-wrap;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}

.hover-tooltip div:first-child {
  height: 1.8rem;
}

.page-amount-input,
.search-type-dis {
  width: 4.5rem;
  height: 1.7rem;
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(243 243 243);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  padding: 0 0.7rem;
  color: #67748e;
}

.search-type-dis {
  width: 8.5rem;
}

.list-group li {
  margin-bottom: 1rem;
}

.date-range-input {
  height: 1.7rem;
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(243 243 243);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  color: #67748e;
}

.tgl-page-dropdown {
  width: 0.78rem;
  margin-left: -1.3rem;
}

.tgl-page-dropdown:hover {
  cursor: pointer;
}

.page-num-options,
.search-type-options {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  width: 4.5rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 2rem;
  max-height: 10rem;
  overflow-y: auto;
}

.search-type-options {
  width: 8.5rem;
}

.page-num-options div,
.search-type-options div {
  background-color: rgb(243 243 243);
  padding: 0.2rem 0.7rem;
  width: 100%;
}

.page-num-options div:first-child,
.search-type-options div:first-child {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.page-num-options div:last-child,
.search-type-options div:last-child {
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.page-num-options div:hover,
.search-type-options div:hover {
  cursor: pointer;
  background-color: rgb(235, 235, 235);
}

.view-contract:hover {
  cursor: pointer;
}

.page-btn-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: right;
  margin: 0 0 1rem 0;
  padding-right: 0.7rem;
}

.page-btn {
  width: 2.2rem;
  height: 2.2rem;
  font-size: larger;
  border-radius: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.4rem;
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(222, 222, 222);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}

.page-btn:hover {
  cursor: pointer;
}

.back-page,
.forw-page {
  font-size: larger;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  margin: 0.1rem 0 0.1rem 0.4rem;
}

.unactive {
  background-color: rgb(235 235 235);
  border: 1px solid rgb(236 235 235);
  color: #b2b5bc;
}

.unactive:hover {
  cursor: default;
}

.selected-page-btn {
  color: white;
  border: none;
  background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
}

@media (max-width: 840px) {
  .fit-content-l840 {
    width: fit-content;
  }
}

.desc-tooltip {
  padding: 0.2rem 1rem !important;
  width: fit-content !important;
  position: absolute !important;
  margin-top: 1.6rem !important;
  background-color: #e8e8e8 !important;
  max-width: 25rem !important;
  overflow-x: hidden;
  z-index: 5;
  margin-right: 10rem;
}

.edit-desc-svg {
  height: 1rem;
  fill: rgb(180, 180, 180);
  margin-right: 0.3rem;
  cursor: pointer;
}

.edit-desc-svg:hover {
  fill: rgb(161, 161, 161);
}
@media (max-width: 900px) {
  .desktop-logic {
    display: none !important;
  }

  .mobile-logic {
    display: block !important;
  }
}

@media (min-width: 900px) {
  .desktop-logic {
    display: block !important;
  }

  .mobile-logic {
    display: none !important;
  }
}
</style>

<!-- Mobile Styling -->
<style scoped>
.header {
  font-size: 1.5rem;
  font-weight: 400;
}

.memo-body {
  padding: 1rem;
  padding-top: 0.6rem;
}

.memo {
  padding: 0.8rem;
  margin-bottom: 0.8rem;
}

.memo:last-child {
  margin-bottom: 0;
}

#memo-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  background-color: rgba(15, 15, 15, 0.527);
  width: 100%;
  height: 100%;
  padding-top: 20%;
}

#memo-modal {
  border-radius: 1rem;
  background-color: rgb(240, 240, 240);
}

.memo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1rem;
}

.memo-header span {
  font-size: x-large;
}

.memo-header svg {
  width: 1rem;
  fill: #2c3e50;
  cursor: pointer;
}

.memo div:first-child {
  font-size: 0.8rem;
  word-break: break-all;
}

@media (max-width: 600px) {
  #memo-modal {
    min-width: 95%;
    max-width: 95%;
    margin: 0 2.5%;
  }
}

.tasks-card {
  padding: 0.5rem 1rem;
  background-color: #c8d3d8;
}

.page-sel-btn {
  font-weight: 0;
  background-color: #fff;
  padding: 0.2rem 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border-radius: 0.3rem;
  transition: 1687ms;
  transform: translateY(0);
  cursor: pointer;
  font-size: 0.8rem;
  margin-right: 0.3rem;
}

.page-sel-btn + .selected {
  background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
  color: white;
}

.page-sel-btn + .disabled {
  background-color: #d0d0d0;
}

.page-sel-btn:last-child {
  margin-right: 0;
}

.page-selector {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.page-arrow {
  width: 0.6rem;
  fill: #344767;
}

.page-sel-btn + .disabled .page-arrow {
  fill: #666d7a;
}

.open-action {
  margin-bottom: 1rem;
}

.open-action:last-child {
  margin-bottom: 0;
}

.open-actions-card {
  border-radius: 1rem;
  padding: 1rem;
  /* background-color: rgb(240, 240, 240); */
  /* background-color: rgba(159, 168, 184, 0.849);  
      backdrop-filter: blur(5px); */
}

.extend-action {
  width: 6%;
  padding: 0;
  margin: 0.2rem 2.5% auto 2.5%;
  cursor: pointer;
  fill: #344767;
}

.extend-tasks {
  width: 6%;
  padding: 0;
  margin: 0rem 2.5%;
  cursor: pointer;
  fill: #344767;
}

.subject {
  max-width: 89%;
  word-wrap: normal;
  margin: 0;
}

.open-action-header {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.open-action-header svg {
  width: 1.9rem;
  height: 1.9rem;
  fill: #2c3e50;
  margin-right: 0.5rem;
}

.open-action-cont {
  padding: 0.6rem 1rem;
  margin-bottom: 0.5rem;
}

.prog-upt-btn {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

@media (min-width: 600px) {
  .my-actions-main {
    padding: 0 20%;
  }

  .extend-action {
    width: 1.5rem;
  }

  .extend-tasks {
    width: 1.5rem;
  }

  #memo-modal {
    margin: 0 26%;
    width: 48%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: calc(6rem / 3);
  height: calc(3.4rem / 3);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input[type="date"] {
  box-sizing: border-box;
  padding: 0 0.7rem;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(226, 60, 60);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.disabled-switch .slider {
  background-color: rgb(161, 73, 73);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: calc(2.6rem / 3);
  width: calc(2.6rem / 3);
  left: calc(0.4rem / 3);
  bottom: calc(0.4rem / 3);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.table td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}

.table th {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

input:checked + .slider {
  background-color: #47e441;
}

input:focus + .slider {
  box-shadow: 0 0 1px #47e441;
}

.disabled-switch input:checked + .slider {
  background-color: #64be61;
}

.disabled-switch input:focus + .slider {
  box-shadow: 0 0 1px #64be61;
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc(2.6rem / 3));
  -ms-transform: translateX(calc(2.6rem / 3));
  transform: translateX(calc(2.6rem / 3));
}

/* Rounded sliders */
.slider.round {
  border-radius: calc(3.4rem / 3);
}

.slider.round:before {
  border-radius: 50%;
}

.loader {
  border: 0.7rem solid #f3f3f3;
  /* Light grey */
  border-top: 0.7rem solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  animation: spin 2s linear infinite;
}

.loader-container {
  height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .width-100-l600 {
    width: 100% !important;
  }
  .pd-t-l600 {
    padding: 0.75rem 0rem 0rem 0rem !important;
  }
  .pd-tb-l100 {
    padding: 0.75rem 0rem !important;
  }
  .pd-lr-l600 {
    padding: 0rem 0.75rem !important;
  }
}
</style>

<template>

    <div style="padding: 0.5rem 1.5rem;">
        <navBarVue style="background-color: #6F9600;" />
        <div style="margin: 0.75rem 0;">
            <div class="leave-bar">
                <svg @click="navBack()" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                        d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                </svg>
            </div>
            <div class="card" style="padding: 1rem;">
                <div id="main-contract" style="background-color: White; padding: 1.5rem; color: black;">
                    <div
                        style="border-top: 1px solid black; border-bottom: 1px solid black; padding: 0.5rem 0; width: 100%; text-align-center; font-size: 1.1rem; display: flex; justify-content: center;">
                        <b v-if="getCurrentAuditToSign.reason == 'Exit Plan'">Exit Plan</b>
                        <b v-else>Employment Action Plan</b>
                    </div>
                    <p style="font-size: 0.8rem;">The following plan demonstrates a commitment between
                        {{getCustomer.fullName}} and {{getCustomer.advisor.fullName}} to undertake the following actions
                        to achieve
                        employment.
                    </p>
                    <table style="width: 100%; margin: 1rem 0;">
                        <thead>
                            <tr>
                                <td style="border: 1px solid black; background-color: lightgrey;">
                                    <b style="background-color: lightgrey;">Participant Details</b>
                                </td>
                                <td style="border: 1px solid black; background-color: lightgrey;">
                                    <b style="background-color: lightgrey;">Personal Advisor Details</b>
                                </td>
                            </tr>
                        </thead>
                        <tbody style="font-size: 0.8rem;">
                            <tr>
                                <td style="border: 1px solid black;">
                                    Participant Name: {{getCustomer.fullName}}
                                </td>
                                <td style="border: 1px solid black;">
                                    Personal Advisor Name: {{getCustomer.advisor.fullName}}
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;">
                                    Participant Reference: {{getCustomer.memorableId}}
                                </td>
                                <td style="border: 1px solid black;">
                                    Personal Advisor Office: {{office}}
                                </td>
                            </tr>
                            <tr>
                                <td v-if="getCurrentAuditToSign.reason == 'Exit Plan' && customerProgram == 'FSS'" style="border: 1px solid black;">
                                    Participant NINO: {{ this.customerNino }}
                                </td>
                                <td v-else></td>
                                <td style="border: 1px solid black;">
                                    Personal Advisor Phone: {{cscPhone}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table style="width:100%; padding: margin: 1rem 0 0.6rem 0;">
                        <thead style="width:100%;">
                            <tr style="width:100%;">
                                <td style="border: 1px solid black; background-color: lightgrey; width:100%;">
                                    <b style="background-color: lightgrey;">Signed Agreement</b>
                                </td>
                            </tr>
                        </thead>
                    </table>
                    <div style="font-size: 0.8rem;">By signing below, both parties confirm the full action plan has been
                        reviewed and agreed.</div>
                    <table style="width: 100%; margin: 1rem 0;">
                        <tbody style="font-size: 0.8rem;">
                            <tr>
                                <td style="border: 1px solid black; width: 33.333%; padding-bottom: 5rem;">
                                    Customer Signature
                                </td>
                                <td style="border: 1px solid black; width: 33.333%; padding-bottom: 5rem;">
                                </td>
                                <td style="border: 1px solid black; width: 33.333%; padding-bottom: 5rem;">
                                    Date:
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; width: 33.333%; padding-bottom: 5rem;">
                                    Advisor Signature
                                </td>
                                <td style="border: 1px solid black; width: 33.333%; padding-bottom: 5rem;">
                                </td>
                                <td style="border: 1px solid black; width: 33.333%; padding-bottom: 5rem;">
                                    Date:
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="margin-top: 1rem;"><b>Formal Review</b></div>
                    <table style="width: 100%; margin-bottom: 1rem;">
                        <thead>
                            <tr>
                                <td style="border: 1px solid black; width: 40%; background-color: lightgrey;">
                                    <b style="background-color: lightgrey;">Subject</b>
                                </td>
                                <td style="border: 1px solid black; width: 60%; background-color: lightgrey;">
                                    <b style="background-color: lightgrey;">Progress Notes</b>
                                </td>
                            </tr>
                        </thead>
                        <tbody style="font-size: 0.8rem;">
                            <tr>
                                <td style="border: 1px solid black;">
                                    {{formatDate(new Date())}}
                                </td>
                                <td style="border: 1px solid black;">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="margin-top: 1rem;"><b>Job Goals</b></div>
                    <table style="width: 100%; margin-bottom: 1rem;">
                        <thead>
                            <tr>
                                <td style="border: 1px solid black; width: 33.333%; background-color: lightgrey;">
                                    <b style="background-color: lightgrey;">Job Goal 1</b>
                                </td>
                                <td style="border: 1px solid black; width: 33.333%; background-color: lightgrey;">
                                    <b style="background-color: lightgrey;">Job Goal 2</b>
                                </td>
                                <td style="border: 1px solid black; width: 33.333%; background-color: lightgrey;">
                                    <b style="background-color: lightgrey;">Job Goal 3</b>
                                </td>
                            </tr>
                        </thead>
                        <tbody style="font-size: 0.8rem;">
                            <tr>
                                <td style="border: 1px solid black;">
                                    {{getCustomer.sectorInterests.length >= 1 ? getCustomer.sectorInterests[0] : ''}}
                                </td>
                                <td style="border: 1px solid black;">
                                    {{getCustomer.sectorInterests.length >= 2 ? getCustomer.sectorInterests[1] : ''}}
                                </td>
                                <td style="border: 1px solid black;">
                                    {{getCustomer.sectorInterests.length >= 3 ? getCustomer.sectorInterests[2] : ''}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="margin: 1.2rem 0 0.5rem 0;"><b>Action Plan Items</b></div>
                    <div style="margin-top: 0;"><b>Open Actions</b></div>
                    <table style="width: 100%; margin-bottom: 1rem;">
                        <thead>
                            <tr>
                                <td style="border: 1px solid black; background-color: lightgrey; width: 25%;">
                                    <b style="background-color: lightgrey;">My Open Action(s)</b>
                                </td>
                                <td style="border: 1px solid black; background-color: lightgrey; width: 40%;">
                                    <b style="background-color: lightgrey;">What I will get from doing this</b>
                                </td>
                                <td style="border: 1px solid black; background-color: lightgrey; width: 10%;">
                                    <b style="background-color: lightgrey;">Created on</b>
                                </td>
                                <td style="border: 1px solid black; background-color: lightgrey; width: 10%;">
                                    <b style="background-color: lightgrey;">Due</b>
                                </td>
                                <td style="border: 1px solid black; background-color: lightgrey; width: 25%;">
                                    <b style="background-color: lightgrey;">Progress Update</b>
                                </td>
                            </tr>
                        </thead>
                        <tbody style="font-size: 0.8rem;">
                            <tr v-for="action in getActions.current" :key="action.id">
                                <td style="border: 1px solid black;">
                                    {{action.subject}}
                                </td>
                                <td style="border: 1px solid black;">
                                    {{action.motivation}}
                                </td>
                                <td style="border: 1px solid black;">
                                    {{action.state.stateCode}} {{formatDate(new Date(action.audit.createdOn))}}
                                </td>
                                <td style="border: 1px solid black;">
                                    {{action.state.stateCode}} {{formatDate(new Date(action.timeline.scheduledEnd))}}
                                </td>
                                <td style="border: 1px solid black; word-break: break-all;">
                                    {{action.memos.length > 0 ? formatDate(new
                                    Date(action.memos[action.memos.length-1].createdOn)) + " - " +
                                    action.memos[action.memos.length-1].body : ''}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="margin-top: 0;"><b>Completed Actions</b></div>
                    <table style="width: 100%; margin-bottom: 1rem;">
                        <thead>
                            <tr>
                                <td style="border: 1px solid black; background-color: lightgrey; width: 25%;">
                                    <b style="background-color: lightgrey;">My Completed Action(s)</b>
                                </td>
                                <td style="border: 1px solid black; background-color: lightgrey; width: 40%;">
                                    <b style="background-color: lightgrey;">What I will get from doing this</b>
                                </td>
                                <td style="border: 1px solid black; background-color: lightgrey; width: 10%;">
                                    <b style="background-color: lightgrey;">Closed Date</b>
                                </td>
                                <td style="border: 1px solid black; background-color: lightgrey; width: 25%;">
                                    <b style="background-color: lightgrey;">Progress Update</b>
                                </td>
                            </tr>
                        </thead>
                        <tbody style="font-size: 0.8rem;">
                            <tr v-for="action in getActions.completed" :key="action.id">
                                <td style="border: 1px solid black;">
                                    {{action.subject}}
                                </td>
                                <td style="border: 1px solid black;">
                                    {{action.motivation}}
                                </td>
                                <td style="border: 1px solid black;">
                                    {{action.state.stateCode}} {{action.timeline.actualEnd != null ? this.formatDate(new
                                    Date(action.timeline.actualEnd)) : "N/A"}}
                                </td>
                                <td style="border: 1px solid black; word-break: break-all;">
                                    {{action.memos.length > 0 ? formatDate(new
                                    Date(action.memos[action.memos.length-1].createdOn)) + " - " +
                                    action.memos[action.memos.length-1].body : ''}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <FooterVue />
    </div>
</template>
<script>

    import 'survey-core/modern.min.css';
    import "bootstrap/dist/css/bootstrap.min.css";
    import { mapActions, mapState } from 'pinia';
    import { useCommonStore } from '@/store/commonStore';
    import navBarVue from '@/components/navBar.vue';
    import FooterVue from '@/components/Footer.vue';
    import { NavBackwards } from "@/router/index";
    import { contractFilter } from '@/composables/contractFilter';
    
export default {
    name: "preview-contract-vue",
    data() {
        return {
            ea: null,
            office: ""
        }
    },
    components: {
        navBarVue,
        FooterVue
    },
    computed: {
        ...mapState(useCommonStore, ['getActionPlan', 'getCustomer', 'getActions', 'getCurrentAuditToSign', "getPRaPOrder"]),
        cscPhone() {
            return process.env.VUE_APP_MANDATION_PROVIDER_TELEPHONE
        },
        customerProgram() {
            const { customerProgram } = contractFilter();
            return customerProgram?.value || null; // Return the value or null if undefined
        },
        currentPrap() {
            return this.getPRaPOrder?.documents?.slice(-1)[0] || null;
        },

        customerNino() {
            const currentPRaP = this.currentPrap;
            return currentPRaP ? currentPRaP.extraInfoLine.ninoReferenceNo : "N/A";
        },
    },
    methods: {
        ...mapActions(useCommonStore, ['SetCustomerId', 'GetActionPlan', 'GetCustomer', 'GetAuditActions']),
        formatDate(date) {
            return [
                date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
                (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
                date.getFullYear()
            ].join('/');
        },
        navBack() {
            NavBackwards();
        },
    },
    async mounted() {
        this.SetCustomerId(this.$route.params.id);
        this.GetActionPlan().then(() => {
            this.getActions.current.sort((a, b) => new Date(b.timeline.scheduledEnd) - new Date(a.timeline.scheduledEnd));
        });
        await this.GetCustomer();
        this.office = this.getCustomer.owningTeam.name
    }
}
</script>
<style scoped>
    .leave-bar{
        height: fit-content;
        width: 100%;
        display: flex;
        justify-content: right;
        margin-bottom: -2.3rem;
        padding: 0.3rem 0.3rem 0 0;
        padding-right: 0.6rem;
    }
    
    .leave-bar svg{
        width: 2rem;
        height: 2rem;
        z-index: 1;
        cursor: pointer;
    }
    
    .leave-bar svg:hover{
        fill: #243147;
    }
</style>
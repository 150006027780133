import { ref } from 'vue';
import { defineStore } from "pinia";
import { useForm } from "vee-validate";
import * as Yup from "yup";
import Dwp603Record from '../interfaces/Dwp603Record'

export const uc603FormStoreSchema = Yup.object({
  selectedAdvisor: Yup.string().required("This field is required*"),
  mandateParticipant: Yup.string().required("This field is required*"),
  detailedActivity: Yup.string().required("This field is required*"),
  methodOfContact: Yup.string().required("This field is required*"),
  provideAdditionalDetails: Yup.string().required("This field is required*"),
  complexNeeds: Yup.string().required("This field is required*"),
  reasonable: Yup.string().required("This field is required*"),
  explainActions: Yup.string().required("This field is required*"),
  face2Face: Yup.string().when("rearrangeMandateActivity", {
    is: "yes",
    then: Yup.string().required("This field is required*"),
  }),
});

export const uc603FormStore = defineStore("uc603FormStore", () => {
  const { errors, useFieldModel, handleSubmit, meta } = useForm({
    validationSchema: uc603FormStoreSchema,
  });

  // Prevents overwriting changed form data when changing pages
  const loadedSavedForm = ref(false)

  const selectedAdvisor = useFieldModel("selectedAdvisor");
  const mandateParticipant = useFieldModel("mandateParticipant");
  const detailedActivity = useFieldModel("detailedActivity");
  const methodOfContact = useFieldModel("methodOfContact");
  const provideAdditionalDetails = useFieldModel("provideAdditionalDetails");
  const complexNeeds = useFieldModel("complexNeeds");
  const reasonable = useFieldModel("reasonable");
  const explainActions = useFieldModel("explainActions");
  const face2Face = useFieldModel("face2Face");

  const submitForm = handleSubmit(async (values, actions) => {
    console.log("submitted");
  });

  const loadFromSavedRecord = function(dwp603Record: Dwp603Record) {
    const mandatableDetails = dwp603Record.mandatableDetails

    selectedAdvisor.value = dwp603Record.completedBy
    mandateParticipant.value = convertBool(mandatableDetails.mandateParticipant)
    detailedActivity.value = mandatableDetails.detailedActivity
    methodOfContact.value = convertBool(mandatableDetails.methodOfContact)
    provideAdditionalDetails.value = mandatableDetails.provideAdditionalDetails
    complexNeeds.value = convertBool(mandatableDetails.complexNeeds)
    reasonable.value = convertBool(mandatableDetails.reasonable)
    explainActions.value = mandatableDetails.explainActions
    
    loadedSavedForm.value = true
  }

  const resetData = function() {
    mandateParticipant.value = "";
    detailedActivity.value = "";
    methodOfContact.value = "";
    provideAdditionalDetails.value = "";
    complexNeeds.value = "";
    reasonable.value = "";
    explainActions.value = "";
    face2Face.value = "";
    loadedSavedForm.value = false
    selectedAdvisor.value = null
  }

  function convertBool(value: boolean) {
    return value == true ? "yes" : value == false ? "no" : ""
  }
  
  return {
    selectedAdvisor,
    loadedSavedForm,
    mandateParticipant,
    detailedActivity,
    methodOfContact,
    provideAdditionalDetails,
    complexNeeds,
    reasonable,
    explainActions,
    face2Face,
    errors,
    handleSubmit,
    meta,
    loadFromSavedRecord,
    resetData
  };
});

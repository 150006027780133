import { computed } from 'vue';
import { useCommonStore } from '../store/commonStore'; // Adjust the import path as needed

// Define the GUIDs for each business unit
const BUSINESS_UNIT_GUIDS = {
  Restart: 'd2b07766-9a8b-4a73-a2ee-e11accef5905',
  FSS: '073e01c9-ced1-4939-9533-c66e23a7f87b',
  EC: '072e01c9-ced1-4939-9533-c66e23a7f87b',
};


export function contractFilter() {
  // Access the commonStore
  const commonStore = useCommonStore();

  // Computed property to determine the customer program based on the owningBusinessUnit
  const customerProgram = computed(() => {
    // Get and trim owningBusinessUnit, in case there are extra spaces
    const owningBusinessUnit = commonStore.getCustomer.owningBusinessUnit?.trim();
    
    // Log for debugging to ensure the value is correctly fetched
    console.log('owningBusinessUnit:', owningBusinessUnit);

    // Check if owningBusinessUnit is available before proceeding with comparison
    if (!owningBusinessUnit) {
      return 'Unknown';
    }

    // Compare the owningBusinessUnit with the predefined GUIDs and return the program name
    if (owningBusinessUnit === BUSINESS_UNIT_GUIDS.Restart) {
      return 'Restart';
    } else if (owningBusinessUnit === BUSINESS_UNIT_GUIDS.FSS) {
      return 'FSS';
    } else if (owningBusinessUnit === BUSINESS_UNIT_GUIDS.EC) {
      return 'EC';
    }

    // If no match is found, return 'Unknown'
    return 'Unknown';
  });

  // Return the computed value for use in the component
  return {
    customerProgram,
  };
}

<template>
  <div
    class="multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="man-title">{{ getCustomer.fullName }}, UC603 Form</h5>
    <p class="man-sub-title">
      The following information will be used to complete a compliance doubt for
      {{ getActionToMandate ? getActionToMandate.subject : "" }}
    </p>
    <div class="multisteps-form__content">
      <form class="card-body">

        <div class="rad-input-text-inline">
          <label class="man-text">What did you mandate the participant to do?</label>
          <div class="radio-group d-flex">
            <label class="rad-label me-4">
              <input
                type="radio"
                name="mandate"
                v-model="store.mandateParticipant"
                value="yes"
                class="form-check-input"
              />
              <span class="rad-text">Attend and take part in an appointment</span>
            </label>
            <label class="rad-label">
              <input
                type="radio"
                name="mandate"
                v-model="store.mandateParticipant"
                value="no"
                class="form-check-input"
              />
              <span class="rad-text">Undertake work-related activity</span>
            </label>
          </div>
          <p class="required-colour">
              {{ store.errors.mandateParticipant }}
            </p>
        </div>
        <div class="rad-input-text-inline mt-3">
          <label class="man-text">
            If the participant was mandated to undertake work-related activity, provide details of that activity
          </label>
          <textarea
            v-model="store.detailedActivity"
            class="form-control"
            rows="3"
            placeholder="provide details of activity..."
          ></textarea>
          <p class="required-colour">
              {{ store.errors.detailedActivity }}
            </p>
        </div>
        <div class="rad-input-text-inline mt-3">
          <label class="man-text">How was the MAN issued?</label>
          <div class="radio-group d-flex">
            <label class="rad-label me-4">
              <input
                type="radio"
                name="methodOfIssue"
                v-model="store.methodOfContact"
                value="yes"
                class="form-check-input"
              />
              <span class="rad-text">Post</span>
            </label>
            <label class="rad-label">
              <input
                type="radio"
                name="methodOfIssue"
                v-model="store.methodOfContact"
                value="no"
                class="form-check-input"
              />
              <span class="rad-text">In Person</span>
            </label>
          </div>
          <p class="required-colour">
              {{ store.errors.methodOfContact }}
            </p>
        </div>
        <div class="rad-input-text-inline mt-3">
          <label class="man-text">
            Provide additional details of the mandate (e.g., full address used)
          </label>
          <textarea
            v-model="store.provideAdditionalDetails"
            class="form-control"
            rows="3"
            placeholder="Enter details here..."
          ></textarea>
          <p class="required-colour">
              {{ store.errors.provideAdditionalDetails }}
            </p>
        </div>
        <div class="rad-input-text-inline mt-3">
          <label class="man-text">
            Does the participant have any known vulnerabilities or complex needs?
          </label>
          <div class="radio-group d-flex">
            <label class="rad-label me-4">
              <input
                type="radio"
                name="complexNeeds"
                v-model="store.complexNeeds"
                value="yes"
                class="form-check-input"
              />
              <span class="rad-text">Yes</span>
            </label>
            <label class="rad-label">
              <input
                type="radio"
                name="complexNeeds"
                v-model="store.complexNeeds"
                value="no"
                class="form-check-input"
              />
              <span class="rad-text">No</span>
            </label>
          </div>
          <p class="required-colour">
              {{ store.errors.complexNeeds }}
            </p>
        </div>
        <div class="rad-input-text-inline mt-3">
          <label class="man-text">
            Was the required activity reasonable, taking into account the participant's circumstances?
          </label>
          <div class="radio-group d-flex">
            <label class="rad-label me-4">
              <input
                type="radio"
                name="reasonableActivity"
                v-model="store.reasonable"
                value="yes"
                class="form-check-input"
              />
              <span class="rad-text">Yes</span>
            </label>
            <label class="rad-label">
              <input
                type="radio"
                name="reasonableActivity"
                v-model="store.reasonable"
                value="no"
                class="form-check-input"
              />
              <span class="rad-text">No</span>
            </label>
          </div>
          <p class="required-colour">
              {{ store.errors.reasonable }}
            </p>
        </div>
        <div class="rad-input-text-inline mt-3">
          <label class="man-text">
            Please explain in detail what actions you have taken (e.g., a timeline of attempted contact or additional support provided to the participant)
          </label>
          <textarea
            v-model="store.explainActions"
            class="form-control"
            rows="3"
            placeholder="Provide detailed explanation..."
          ></textarea>
          <p class="required-colour">
              {{ store.errors.explainActions }}
            </p>
        </div>
      </form>
      <div class="mt-4 button-row d-flex" style="justify-content: flex-end">
        <button
          class="mb-0 btn bg-gradient-dark ms-4 js-btn-next"
          type="button"
          title="Close without saving"
          @click="resetData()"
        >
          Close without saving
        </button>
        <button
          :disabled="!store.meta.valid"
          class="mb-0 btn bg-gradient-dark ms-4 js-btn-next"
          type="button"
          title="Next"
          id="confirm-603-form-checkbox-part-1"
          @click="this.$parent.nextStep"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>


<script setup>
import { uc603FormStore } from "../../../store/UC603FormStore";
import { useCommonStore } from "@/store/commonStore";
import { uc603FormStore2 } from "../../../store/UC603FormStore2";
const store = uc603FormStore();
const store2 = uc603FormStore2();
const commonStore = useCommonStore();
const { getAllAdvisors, getCustomer, setUc603Visibility } = useCommonStore();

function resetData() {
  store.resetData();
  store2.resetData();
  setUc603Visibility(false);
}

function getLatest603() {
  const dwp603Records = commonStore.getActionToMandate.mandationRecord.dwp603Records;
  if (dwp603Records.length > 0) {
    return dwp603Records[dwp603Records.length - 1];
  }
}

function getDefaultAdvisorId() {
  const found = getAllAdvisors.filter(a => a.email == loggedIn.email)[0]

  if (found == null) {
    return getCustomer.advisor.id
  }

  return found.contactId
}

onMounted(() => {
  // It is possible that there isn't a saved 603 at this point
  const latest = getLatest603();

  // Load saved form data if not already loaded
  if (latest != null && !store.loadedSavedForm) {
    store.loadFromSavedRecord(latest); 
  } 
  
  // Default the selected advisor to the logged in user
  if (store.selectedAdvisor == null) {
    store.selectedAdvisor = getDefaultAdvisorId()
  }
});
</script>

<script>
import { mapState } from "pinia";
import { onMounted } from "vue";
import { loggedIn } from '../../../router/Guard';

export default {
  computed: {
    ...mapState(useCommonStore, [
      "getAllAdvisors",
      "getActionToMandate",
      "getSavedManData",
    ]),
    filteredAdvisors() {
      let advisors = this.getAllAdvisors.filter((a) => {
        return this.getContactDisplay(a) != "";
      });
      return advisors.sort(this.contactSort);
    },
  },
  methods: {
    contactSort(a, b) {
      let aDisplay = this.getContactDisplay(a).toLowerCase();
      let bDisplay = this.getContactDisplay(b).toLowerCase();
      if (aDisplay == bDisplay) {
        return 0;
      }
      return aDisplay < bDisplay ? -1 : 1;
    },
    getContactDisplay(contact) {
      let firstName = contact.firstName != null ? contact.firstName.trim() : "";
      let lastName = contact.lastName != null ? contact.lastName.trim() : "";
      let fullName = contact.fullName != null ? contact.fullName.trim() : "";
      if (fullName == null || fullName == "") {
        fullName = firstName + " " + lastName;
      }
      return fullName.trim();
    },
  },
};
</script>

<style scoped>
.required-colour {
  color: red;
}

.man-title {
  color: #595959;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: inherit;
}

.man-sub-title {
  color: #595959;
  font-size: 1rem;
  font-weight: 600;
  font-family: inherit;
}

.man-text {
  font-size: 0.9rem;
  font-weight: 700;
  color: #595959;
}

.rad-text {
  color: #595959;
  margin-left: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 900;
  transition: 0.3s;
}

.rad-input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-input-text-inline {
  /* display: flex; */
  text-align: left;
  flex-direction: row;
  margin-bottom: 5px;
}

.rad-label {
  display: flex;
  padding-right: 40px;
}

.radio-group {
  display: flex;
}

.form-check-input{
  background-color: hsl(0, 0%, 90%);
}
.form-check-input:checked[type=radio]{
  background-image: linear-gradient(310deg, #595959 0%, #595959 100%);
}
.man-title {
  color: #595959;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: inherit;
}

.rad-input-text-inline {
  /* display: flex; */
  text-align: left;
  flex-direction: row;
  margin-bottom: 5px;
}
</style>

<template>
  <div class="p-3 bg-white card border-radius-xl js-active" data-animation="FadeIn">
    <div class="text-center row">
      <div class="mx-auto col-12">
        <h5 class="font-weight-normal">
          Please check the details entered are correct.
        </h5>
        <p>
          If you want to make changes you can go back.
          If everything is correct then please approve and the file will save.
          You will be able to access it through a link in the mandated action.
        </p>
      </div>
    </div>

    <div v-if="documentId != null" style="width: 95%; margin: 0 auto; aspect-ratio: 1.7/1">
      <iframe 
      style="width: 100%; height: 100%" id="preview-iframe"
        :src="getPreviewMandationFormLink"
         width="476px" height="288px" frameborder="0">This is an embedded
        <a target="_blank" href="https://office.com">Microsoft Office</a>
        document, powered by
        <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
    </div>

    <div class="multisteps-form__content">
      <div class="mt-4 button-row d-flex" style="justify-content: flex-end">
        <!-- <button
          class="mb-0 btn bg-gradient-dark ms-4 js-btn-next"
          type="button"
          title="Next"
          @click="openLink()"
        >
          View uploaded document
        </button> -->
        <button class="mb-0 btn bg-gradient-dark ms-4 js-btn-next mandated-button" type="button" title="Next"
          @click="goBack()">
          Go Back
        </button>
        <button class="mb-0 btn bg-gradient-dark ms-4 js-btn-next mandated-button" type="button" title="Next"
          @click="onConfirm()">
          Approve and Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useCommonStore } from "@/store/commonStore";
import { mapState, mapActions } from "pinia";
import { showLoadingAlert, showWarningAlert, showSuccessAlert, showConfirmationAlert } from "@/utils/SwalUtil";

// const ByPost = "By Post"
// const FaceToFace = "Face to Face"

export default {
  name: "JSA_Preview_Download",

  props: {
    activityProvider: {
      type: String,
      default: "Fedcap",
    },
    cscNumber: {
      type: String,
      default: "0800 917 9262",
    },
  },

  data() {
    return {
      COMPLIANCE_CONDITION_CONTACT_US:
        "contact us to arrange an appointment and attend the rearranged appointment.",
      COMPLIANCE_CONDITION_UNDERTAKE_TO: "undertake to ",
      COMPLIANCE_CONDITION_SUPPLY_WITH: "supply us with ",
      COMPLIANCE_CONDITION_AGREE_APPOINTMENT:
        "agree an appointment date and attend the appointment with ",
      selectedAdvisorId: null,
      SALUTATIONS: ["Mr.", "Mrs.", "Miss.", "Ms.", "Mx.", "Dr."],
      showSavePopup: false,
      isF2F: true,
      isByPost: false,
      text: "",
      option:
        "contact us to arrange an appointment and attend the rearranged appointment.",
      undertake: "",
      agree: "",
      supply: "",
      method: "F2F",
      inputValue: this.inputValue1,
      option1: false,
      documentLink: "",
      docName: "",
      documentId: null,
    };
  },

  computed: {
    ...mapState(useCommonStore, [
      "getCustomer",
      "getAllAdvisors",
      "setJsaMandationFormVisability",
      "getActionToMandate",
      "getManFormData",
      "getDueDate",
      "getMandationEmbedGuid",
      "getIsEditingManForm",
      "getPRaPOrder",
    ]),
    getPreviewMandationFormLink(){
      return process.env.VUE_APP_PREVIEW_MANDATION_FORM.replace('{documentId}',this.documentId)
    },
    filteredAdvisors() {
      let advisors = this.getAllAdvisors.filter((a) => {
        return this.getContactDisplay(a) != "";
      });

      return advisors.sort(this.contactSort);
    },
    storeChoices() {
      return {
        isIssuedByPost: this.isByPost,
        isIssuedFaceToFace: this.isF2F,
        requiredEvidence: this.text,
      };
    },

    formAsDTO() {
      const methodOfIssue = "By Post"

      return {
        owningUser:
          // Advisor priority
          // (1) Selected advisor
          // (2) Logged in advisor
          // (3) Customer's advisor (may be different to logged in advisor)
          this.selectedAdvistorId == null
            ? this.loggedInAdvisor == null
              ? this.getCustomer.advisor.id
              : this.loggedInAdvisor.id
            : this.selectedAdvistorId,
        mandatableType: "Action",
        customerName: this.getCustomer.fullName, 
        title: this.getManFormData.Title,
        mandationSubject: this.getActionToMandate.subject,
        includeMap: this.includeMap,
        providerName: this.getManFormData.activityContactName,
        providerPhone: this.cscNumber,
        locationOverride: "no location",
        startTimeOverride: "0:0:0.0000000",
        isPhoneAppointment: false,
        complianceCondition: this.getManFormData.ComplianceCondition,
        complianceDetails: [
          {
            name: "none",
            value: this.getManFormData.inputValue,
          },
        ],
        // [Deprecated] Use methodOfIssue instead
        //isIssuedByPost: this.isByPost,
        //isIssuedFaceToFace: this.isF2f,
        isJobSeeker: true,
        requiredEvidence: this.getManFormData.Evidence,
        addressLine1: this.getCustomer.addressLine1,
        addressLine2: this.getCustomer.addressLine2,
        addressLine3: this.getCustomer.addressLine3,
        city: this.getCustomer.city,
        stateOrProvince: this.getCustomer.stateOrProvince,
        county: this.getCustomer.county,
        country: this.getCustomer.country,
        postalCode: this.getCustomer.postalCode,
        dueDate: this.getActionToMandate.timeline.scheduledEnd,
        methodOfIssue: methodOfIssue
      };
    },
  },

  methods: {
    ...mapActions(useCommonStore, [
      "GetAllAdvisors",
      "PostMandationForm",
      "setPreviewData",
      "getManFormData",
      "setJsaMandationFormVisability",
      "postMandationFormToSharepoint",
      "GetMandationEmbedGuid",
      "setIsEditingManForm",
      "setJsaManDirectFormData",
      "getPRaPOrder",
      "createMANPreviewDocument",
      "createMANRecord"
    ]),

    capitaliseAddress(address) {
      if (address === null) {
        return null;
      }

      const regex = /(?<=^|\s|-)[a-z]/g;

      // convert the address to lowercase and replace matched letters with uppercase
      const capitalisedAddress = address
        .toLowerCase()
        .replace(regex, (match) => match.toUpperCase());

      return capitalisedAddress;
    },

    /**
     * Load the preview of the document.
     */
    loadPreview() {
      showLoadingAlert(this.createPreview(), { title: "Generating JSA-MAN Preview" })
        // Code to run on failure...
        .catch((err) => {
          const store = useCommonStore()
          store.HandleUIError(err)
          this.goBack()
        })
    },

    /**
     * Generate and save the final version of the document.
     */
    onConfirm() {
      showConfirmationAlert({ 
        title: "Proceed with Generating JSA-MAN Form?", 
        text: "This information cannot be changed afterwards"
      }).then((result) => {
        if (result.isConfirmed) {  
          // Show the loading alert
          showLoadingAlert(this.createFinal(), { title: "Generating JSA-MAN Document" })
            // Code to run on success...
            .then(() => {
              showSuccessAlert({ title : "Document Generated"})
              this.closeForm()    
            })
            // Code to run on failure...
            .catch((err) => {
              const store = useCommonStore()
              store.HandleUIError(err)
            })
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          showWarningAlert({ title: "JSA-MAN Form has not been submitted"})         
        }
      })
    },

    goBack() {
      this.$parent.prevStep();
      this.setIsEditingManForm(true);
    },

    contactSort(a, b) {
      let aDisplay = this.getContactDisplay(a).toLowerCase();
      let bDisplay = this.getContactDisplay(b).toLowerCase();

      if (aDisplay == bDisplay) {
        return 0;
      }

      return aDisplay < bDisplay ? -1 : 1;
    },

    getContactDisplay(contact) {
      let firstName = contact.firstName != null ? contact.firstName.trim() : "";
      let lastName = contact.lastName != null ? contact.lastName.trim() : "";

      let fullName = contact.fullName != null ? contact.fullName.trim() : "";
      if (fullName == null || fullName == "") {
        fullName = firstName + " " + lastName;
      }

      return fullName.trim();
    },

    setAdvisorDetails(id) {
      var contact = this.getAllAdvisors.find((c) => c.contactId == id);
      if (contact == undefined) {
        console.error("EA could not be found.");
        return;
      }
      this.customerData.activityContactName = this.getContactDisplay(contact);
      this.selectedAdvisorId = id;
    },
    mandationSaveConfirmation() {
      if (!this.validateForm()) {
        return;
      }

      this.showSavePopup = true;
    },

    cancelPopup() {
      this.showSavePopup = false;
    },

    async closeForm() {
      this.setJsaMandationFormVisability(false);
      this.setJsaManDirectFormData(null);
      this.setIsEditingManForm(false);
    },

    /**
     * Creates a preview of the MAN document
     */
    async createPreview() {
      //await this.PostMandationForm(this.getActionToMandate.id, this.formAsDTO);
      var resp = await this.createMANPreviewDocument(this.getActionToMandate.id, this.formAsDTO);

      this.documentId = await this.GetMandationEmbedGuid(
        resp.documentId
      );
    },

    /**
     * Creates the final version of the MAN, once the user selects save
     */
    async createFinal() {
      await this.createMANRecord(this.getActionToMandate.id, this.formAsDTO);
    },

    openLink() {
      window.open(this.documentLink, "_blank");
    },

    formatCustomerAddress(str) {
      if (str === null) {
        return null;
      }
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // No need to check if i is larger than splitStr length because of the for
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    formatCustomerName(str) {
      if (str === null) {
        return null;
      }
      return str[0] + str.slice(1).toLowerCase();
    },
  },

  async mounted() {
    this.loadPreview()
  },
};
</script>

<style scoped>
.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #999;
  animation: spin 1s infinite linear;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
